// @flow

import * as React from 'react';

import type { Pricing, Capacity, Duration } from 'packages/types';

import { pricesToText, vatText } from 'packages/utils';

import FAIcon from 'common/components/FAIcon';
import Render from 'common/components/Render';

import summarizeCapacity from './helpers/summarizeCapacity';
import summarizeDuration from './helpers/summarizeDuration';

import styles from './PackageAttributes.scss';

function AttributeRow(props: {
  iconName: string,
  label: string,
  children: React.Node,
  ...
}) {
  return (
    <div className={styles.row}>
      <span className={styles.icon}>
        <FAIcon name={props.iconName} />
      </span>
      <span className={styles.rowContent}>
        <span className={styles.label}>
          {props.label}
          {': '}
        </span>
        {props.children}
      </span>
    </div>
  );
}

export function PackageAvailability(props: {
  availability: string,
  ...
}): React.Node {
  return (
    <Render when={!!props.availability}>
      <AttributeRow
        iconName="calendar"
        label={__('meeting_packages.details.availability')}
      >
        {props.availability}
      </AttributeRow>
    </Render>
  );
}

export function PackageDuration(props: {
  duration: Duration,
  ...
}): React.Node {
  const summary = summarizeDuration(props.duration);

  return (
    <Render when={!!summary}>
      <AttributeRow
        iconName="clock-o"
        label={__('meeting_packages.details.duration.label')}
      >
        {summary}
      </AttributeRow>
    </Render>
  );
}

export function PackageCapacity(props: {
  capacity: Capacity,
  ...
}): React.Node {
  const summary = summarizeCapacity(props.capacity);

  return (
    <Render when={!!summary}>
      <AttributeRow
        iconName="user"
        label={__('meeting_packages.details.capacity.label')}
      >
        {summary}
      </AttributeRow>
    </Render>
  );
}

export function PackagePrice(props: { pricing: Pricing, ... }): React.Node {
  return (
    <AttributeRow iconName="usd" label={__('meeting_packages.details.price')}>
      {pricesToText(props.pricing)}{' '}
      <span className={styles.pricingInfo}>
        ({vatText(props.pricing.vatIncluded)})
      </span>
    </AttributeRow>
  );
}

export function PackageName(props: { name: string, ... }): React.Node {
  return (
    <Render when={!!props.name}>
      <div className={styles.name}>{props.name}</div>
    </Render>
  );
}
