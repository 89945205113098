// @flow

import { notBlank } from 'common/predicates';

import type { Pricing } from 'packages/types';

import type { TranslatedPricing } from './pricingTypes';

import toLocalizedPrices from './toLocalizedPrices';
import toTranslatedPrices from './toTranslatedPrices';

export default function pricesToText(pricing: Pricing): string {
  const { vatIncluded: _, ...pricingNumbers } = pricing;

  const p = toTranslatedPrices(toLocalizedPrices(pricingNumbers));

  const additivePrices = costBasedPricing(p).join(' + ');
  const salesBasedPrices = salesBasedPricing(p).join(', ');

  if (additivePrices && salesBasedPrices) {
    return `${additivePrices} (${salesBasedPrices})`;
  } else {
    return additivePrices || salesBasedPrices;
  }
}

function costBasedPricing(p: TranslatedPricing): Array<string> {
  return [
    p.genericFixed || '',
    p.perPerson || '',
    p.rentFixed || '',
    p.rentPerHour || ''
  ].filter(notBlank);
}

function salesBasedPricing(p: TranslatedPricing): Array<string> {
  return [
    p.additionalRentPerHour || '',
    p.salesGuarantee || '',
    p.salesGuaranteePerHour || ''
  ].filter(notBlank);
}
