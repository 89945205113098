// FIXME: Don't use this DateInput component, it is bad.
//  - It has no flow types
//  - It communicates the validity to the parent component in a strange way
//  - It causes a split brain syndrome where this component has some inner state that
//    the parent component does not know about and might really want to know about.

import T from 'prop-types';
import * as React from 'react';
import { dateOfFormat } from 'common/propTypes/dates';
import moment from 'moment';

function parseValue(isoDateString) {
  if (!isoDateString) {
    return null;
  }

  const date = moment.utc(isoDateString, 'YYYY-MM-DD', true);
  return date.isValid() ? date.format('L') : null;
}

class DateInput extends React.Component {
  static propTypes = {
    initialValue: dateOfFormat('YYYY-MM-DD'),
    onValue: T.func,
    required: T.bool,
    value: dateOfFormat('YYYY-MM-DD')
  };

  state = {
    value: parseValue(this.props.value || this.props.initialValue)
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.value !== null &&
      nextProps.value !== this._normalizeDate(this.state.value)
    ) {
      this.setState({ value: parseValue(nextProps.value) });
    }
  }

  render(): React.Node {
    const {
      initialValue: _initialValue,
      onValue: _onValue,
      ...props
    } = this.props;

    return (
      <input
        {...props}
        type="text"
        value={this.state.value || ''}
        onChange={this._onChange}
      />
    );
  }

  _onChange = e => {
    const newValue = e.target.value;
    this.setState(
      {
        value: newValue
      },
      () => {
        const isoDate = this._normalizeDate(newValue);
        if (this.props.onValue) {
          this.props.onValue(isoDate, this._isValid());
        }
      }
    );
  };

  _isValid = () => {
    const value = this.state.value;
    if (value) {
      return this._parseDateStringStrict(value).isValid();
    } else {
      return !this.props.required;
    }
  };

  _parseDateStringStrict = dateString => {
    return moment.utc(dateString, 'l', true);
  };

  _normalizeDate = dateString => {
    const date = this._parseDateStringStrict(dateString);
    return date.isValid() ? date.format('YYYY-MM-DD') : null;
  };
}

export default DateInput;
