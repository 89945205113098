// @flow

import * as React from 'react';

import PackageSection from './PackageSection';

type Props = {|
  title: string,
  children: React.Node
|};

export default function PackageGroup(props: Props): React.Node {
  return (
    <PackageSection>
      <h6>{props.title}</h6>

      {props.children}
    </PackageSection>
  );
}
