// @flow

import * as React from 'react';

import type { SanitizedHtml } from 'common/types';

import type { Description } from 'packages/types';

import Render from 'common/components/Render';

import styles from './PackageDescription.scss';

// Empty rich text editor content HTML
const EMPTY_HTML = '<div><br></div>';
function isDescriptionEmpty(description: Description) {
  return (
    !description ||
    description.__html === '' ||
    description.__html === EMPTY_HTML
  );
}

export function PackageDescription(props: {
  description: SanitizedHtml,
  ...
}): React.Node {
  return (
    <Render when={!isDescriptionEmpty(props.description)}>
      <div className={styles.description}>
        <div dangerouslySetInnerHTML={props.description} />
      </div>
    </Render>
  );
}
