// @flow

import format from './format';
export { EMPTY_PARAM } from './format';
import type { Param, QueryParams } from './types';

export function aboutPath(queryParams?: QueryParams = {}): string {
  return format('/about', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function aboutContactDetailsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/about/contact-details', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function acceptUserInvitationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/invitation/accept', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminArchiveCancelledPath(
  queryParams?: QueryParams = {}
): string {
  return format('/admin/archive/cancelled', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminArchiveSucceededPath(
  queryParams?: QueryParams = {}
): string {
  return format('/admin/archive', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminArchiveUnknownPath(
  queryParams?: QueryParams = {}
): string {
  return format('/admin/archive/unknown-author', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminCateringInquiryPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/admin/inquiries/c-:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminCateringInquiryMessagesPath(
  pathParams: {| catering_inquiry_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/admin/catering_inquiries/:catering_inquiry_id/messages',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function adminCommissionsPath(queryParams?: QueryParams = {}): string {
  return format('/admin/commissions', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminEventRequestAnomalyReportsPath(
  pathParams: {| event_request_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/admin/inquiries/:event_request_id/anomaly_reports',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function adminExportArchivePath(queryParams?: QueryParams = {}): string {
  return format('/admin/archive/export', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminInquiriesPath(queryParams?: QueryParams = {}): string {
  return format('/admin/inquiries', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminMessageTemplatesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/admin/message_templates', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminOrganizationPath(queryParams?: QueryParams = {}): string {
  return format('/admin/organization', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminOrganizationInvoicesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/admin/organization/invoices', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminPriceRequestPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/admin/inquiries/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function adminPriceRequestMessagesPath(
  pathParams: {| price_request_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/admin/inquiries/:price_request_id/messages',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function apiCateringInquiryPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/api/catering/inquiries/:id', pathParams, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV1ATMIndexPath(queryParams?: QueryParams = {}): string {
  return format('/api/v1/atm', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV1EventRequestPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/api/v1/event_requests/:id', pathParams, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV1ListsPath(queryParams?: QueryParams = {}): string {
  return format('/api/v1/lists', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV1PhoneNumberRequestsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/v1/phone_number_requests', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV3GroupingGroupPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/api/v3/grouping/groups/:id', pathParams, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV3GroupingGroupsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/v3/grouping/groups', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV3MessageTemplatePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/api/v3/message_templates/:id', pathParams, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV3MessageTemplatesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/v3/message_templates', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV3PackagesCelebrationPackagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/api/v3/packages/celebration_packages/:id',
    pathParams,
    queryParams,
    { translate: false, prependLocale: false }
  );
}

export function apiV3PackagesCelebrationPackagesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/v3/packages/celebration_packages', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV3PackagesExperiencePackagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/api/v3/packages/experience_packages/:id',
    pathParams,
    queryParams,
    { translate: false, prependLocale: false }
  );
}

export function apiV3PackagesExperiencePackagesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/v3/packages/experience_packages', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV3PackagesMeetingPackagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/api/v3/packages/meeting_packages/:id',
    pathParams,
    queryParams,
    { translate: false, prependLocale: false }
  );
}

export function apiV3PackagesMeetingPackagesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/v3/packages/meeting_packages', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function apiV3PackagesVenuePackagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/api/v3/packages/venue_packages/:id',
    pathParams,
    queryParams,
    { translate: false, prependLocale: false }
  );
}

export function apiV3PackagesVenuePackagesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/v3/packages/venue_packages', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function autocompleteProfilesVenueProfilesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/venues/autocomplete', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function autocompleteStaffProspectsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/prospects/autocomplete', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function ballinPath(queryParams?: QueryParams = {}): string {
  return format('/ballin', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function batchStaffInvoicesPath(queryParams?: QueryParams = {}): string {
  return format('/staff/invoices/batch', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function billingAdminCommonBillingBillingDetailsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/admin/billing_details', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function billingAdminCommonBillingStripePortalRedirectPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/admin/common_billing_stripe_portal_redirect',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function billingAPICommonBillingStripeBillingDetailPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/api/common_billing_stripe_billing_details/:id',
    pathParams,
    queryParams,
    { translate: false, prependLocale: false }
  );
}

export function billingAPICommonBillingStripeCustomerPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/api/common_billing_stripe_customers/:id',
    pathParams,
    queryParams,
    { translate: false, prependLocale: false }
  );
}

export function billingAPICommonBillingStripeCustomersPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/common_billing_stripe_customers', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function billingAPICommonBillingStripeDraftInvoicesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/api/common_billing_stripe_draft_invoices', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function billingStaffCommonBillingStripeDailyInvoicePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/common_billing_stripe_daily_invoices/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function billingStaffCommonBillingStripeDailyInvoicesPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/common_billing_stripe_daily_invoices',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function billingStaffCommonBillingStripeInvoicesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/common_billing_stripe_invoices', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function blogPostPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/blog/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function blogPostsPath(queryParams?: QueryParams = {}): string {
  return format('/blog', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function blogTagPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/blog/hashtag/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function cancelUserRegistrationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/cancel', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function cateringConversationReplyEmailsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/webhooks/catering_conversation_reply_emails',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function cateringLandingPagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function cateringListYourServicePath(
  queryParams?: QueryParams = {}
): string {
  return format('/catering/list-your-service', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function cateringProfileInquiriesPath(
  pathParams: {| profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/catering/:profile_id/inquiries', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function cateringProfileMenusPath(
  pathParams: {| profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/catering/:profile_id/menus', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function cateringSearchPath(queryParams?: QueryParams = {}): string {
  return format('/catering', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function conversationReplyEmailsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/webhooks/conversation_reply_emails', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function dashboardPath(queryParams?: QueryParams = {}): string {
  return format('/dashboard', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function destroyUserSessionPath(queryParams?: QueryParams = {}): string {
  return format('/logout', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function disableFeatureStaffOrganizationPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:id/disable_feature',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function duplicateListPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/lists/:id/duplicate', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function duplicateStaffLandingPagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/landing_pages/:id/duplicate', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editAdminOrganizationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/admin/organization/edit', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editAdminPackagesPath(queryParams?: QueryParams = {}): string {
  return format('/admin/packages/edit', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editGroupsPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:id/groups/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editPackagesPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:id/packages/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editProfilesCateringProfilePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/catering/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editProfilesReviewPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/reviews/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editProfilesSupplierProfilePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/sp/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editProfilesVenueProfilePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editRailsConductorInboundEmailPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/conductor/action_mailbox/inbound_emails/:id/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editReviewPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/reviews/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editStaffCateringLandingPagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/catering_landing_pages/:id/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editStaffCateringServiceAreaCityPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/catering/service_area_cities/:id/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editStaffInvoicePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/invoices/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editStaffLandingPagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/landing_pages/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editStaffOrganizationPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/organizations/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editStaffOrganizationContractPath(
  pathParams: {| organization_id: Param, id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/contracts/:id/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editStaffOrganizationServiceFeePath(
  pathParams: {| organization_id: Param, id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/service_fees/:id/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editStaffSearchSupplierSearchExcludedProfilePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_excluded_profiles/:id/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editStaffSearchSupplierSearchTopLevelCategoryPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/:id/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editStaffSearchSupplierSearchTopLevelCategorySubCategoryPath(
  pathParams: {| supplier_search_top_level_category_id: Param, id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/:supplier_search_top_level_category_id/sub_categories/:id/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editStaffUserPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/users/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editStaffVenuePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/venues/:id/edit', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editStaffVenueRelevanceScoresPath(
  pathParams: {| venue_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/venues/:venue_id/relevance_scores/edit',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function editUserPasswordPath(queryParams?: QueryParams = {}): string {
  return format('/password/edit', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function editUserRegistrationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/edit', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function enableFeatureStaffOrganizationPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:id/enable_feature',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function examplePath(queryParams?: QueryParams = {}): string {
  return format('/example', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function exportAnnualInvoicedSalesBreakdownByOrgStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/csv_exports/export_annual_invoiced_sales_breakdown_by_org',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function exportAnnualInvoicedSalesByOrgStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/csv_exports/export_annual_invoiced_sales_by_org',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function exportAskForReviewsStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/csv_exports/export_ask_for_reviews', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function exportEventRequestsStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/csv_exports/export_event_requests', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function exportLandingPagesStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/csv_exports/export_landing_pages', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function exportMonthlyInquiriesByOrgStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/csv_exports/export_monthly_inquiries_by_org',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function exportNonResponsiveAssigneesStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/csv_exports/export_non_responsive_assignees',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function exportNotRespondedInquiriesStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/csv_exports/export_not_responded_inquiries',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function exportOrganizationsStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/csv_exports/export_organizations', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function exportOrganizationsWithInternalCommentStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/csv_exports/export_organizations_with_internal_comment',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function exportUsersStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/csv_exports/export_users', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function exportVenuesRelevanceScoresStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/csv_exports/export_venues_relevance_scores',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function exportVenuesStaffCSVExportsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/csv_exports/export_venues', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function indexPath(queryParams?: QueryParams = {}): string {
  return format('/index', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function inquiriesSupplierProfileSupplierInquiriesPath(
  pathParams: {| supplier_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/sp/:supplier_profile_id/inquiries', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function instructionsICalPath(queryParams?: QueryParams = {}): string {
  return format('/instructions/ical', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function instructionsReportBookingsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/instructions/report-bookings', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function kisakatsomoskabanVoittajaPath(
  queryParams?: QueryParams = {}
): string {
  return format('/kisakatsomoskaban-voittaja', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function landingPagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function listPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/lists/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function listsPath(queryParams?: QueryParams = {}): string {
  return format('/lists', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function mailEventsStaffCommonMailEventsMailEventPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/mail_events/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function mailEventsStaffCommonMailEventsMailEventsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/mail_events', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function mailEventsWebhooksCommonMailEventsWebhooksPath(
  queryParams?: QueryParams = {}
): string {
  return format('/webhooks/mail_events', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function manageVenuesPath(queryParams?: QueryParams = {}): string {
  return format('/dashboard/venues', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function markAsNotSpamSpamInquiriesStaffCommonSpamInquiriesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/spam_inquiries/mark_as_not_spam', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function markAsRepliedAdminPriceRequestPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/admin/inquiries/:id/mark_as_replied',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function markAsSpamSpamInquiriesStaffCommonSpamInquiriesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/spam_inquiries/mark_as_spam', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newListPath(queryParams?: QueryParams = {}): string {
  return format('/lists/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newPriceRequestPath(queryParams?: QueryParams = {}): string {
  return format('/price_requests/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newProfilesCateringProfileReviewPath(
  pathParams: {| catering_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/catering/:catering_profile_id/reviews/new',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function newProfilesVenueProfileReviewPath(
  pathParams: {| venue_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/venues/:venue_profile_id/reviews/new',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function newRailsConductorInboundEmailPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/conductor/action_mailbox/inbound_emails/new',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function newRailsConductorInboundEmailSourcePath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/conductor/action_mailbox/inbound_emails/sources/new',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function newStaffCateringLandingPagePath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/catering_landing_pages/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newStaffInvoicePath(queryParams?: QueryParams = {}): string {
  return format('/staff/invoices/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newStaffLandingPagePath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/landing_pages/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newStaffOrganizationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/organizations/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newStaffOrganizationContractPath(
  pathParams: {| organization_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/contracts/new',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function newStaffOrganizationServiceFeePath(
  pathParams: {| organization_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/service_fees/new',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function newStaffSearchSupplierSearchTopLevelCategoryPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/new',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function newStaffSearchSupplierSearchTopLevelCategorySubCategoryPath(
  pathParams: {| supplier_search_top_level_category_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/:supplier_search_top_level_category_id/sub_categories/new',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function newStaffVenuePath(queryParams?: QueryParams = {}): string {
  return format('/staff/venues/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newUserInvitationPath(queryParams?: QueryParams = {}): string {
  return format('/invitation/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newUserPasswordPath(queryParams?: QueryParams = {}): string {
  return format('/password/new', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newUserRegistrationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/register', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newUserSessionPath(queryParams?: QueryParams = {}): string {
  return format('/login', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newVenueOwnerFormPath(queryParams?: QueryParams = {}): string {
  return format('/register-venue-owner', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newVenueOwnerRegistrationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/add-your-venue', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function newVenueReviewPath(
  pathParams: {| venue_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:venue_id/reviews/new', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function noneOutcomesCommonOutcomesEventCandidatePath(
  pathParams: {| token: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/event_feedback/:token/none', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function offPlatformOutcomesCommonOutcomesEventCandidatePath(
  pathParams: {| token: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/event_feedback/:token/off_platform',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function outcomesAPICommonOutcomesEventCandidatePath(
  pathParams: {| token: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/api/event_candidates/:token', pathParams, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function outcomesAPIPossibleOutcomePipedriveProjectPath(
  pathParams: {| possible_outcome_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/api/possible_outcomes/:possible_outcome_id/pipedrive_project',
    pathParams,
    queryParams,
    { translate: false, prependLocale: false }
  );
}

export function partnerInboundListYourEventServicePath(
  queryParams?: QueryParams = {}
): string {
  return format('/s/list-your-event-service', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function pitopalveluPath(queryParams?: QueryParams = {}): string {
  return format('/pitopalvelu', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function pitopalveluEspooPath(queryParams?: QueryParams = {}): string {
  return format('/pitopalvelu-espoo', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function pitopalveluHelsinkiPath(
  queryParams?: QueryParams = {}
): string {
  return format('/pitopalvelu-helsinki', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function pitopalveluTamperePath(queryParams?: QueryParams = {}): string {
  return format('/pitopalvelu-tampere', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function pitopalveluTurkuPath(queryParams?: QueryParams = {}): string {
  return format('/pitopalvelu-turku', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function pitopalveluVantaaPath(queryParams?: QueryParams = {}): string {
  return format('/pitopalvelu-vantaa', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function priceRequestsPath(queryParams?: QueryParams = {}): string {
  return format('/price_requests', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function privacyPolicyPath(queryParams?: QueryParams = {}): string {
  return format('/privacy-policy', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesCateringProfilePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/catering/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesCateringProfileImagesPath(
  pathParams: {| catering_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/catering/:catering_profile_id/images',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function profilesCateringProfileReviewsPath(
  pathParams: {| catering_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/catering/:catering_profile_id/reviews',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function profilesCateringProfilesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/catering', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesReviewPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/reviews/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesStaffVenueProfileDuplicationsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/venue_profile_duplications', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesSupplierProfilePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/sp/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesSupplierProfileImagesPath(
  pathParams: {| supplier_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/sp/:supplier_profile_id/images', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesSupplierProfilesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/sp', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesVenueProfilePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesVenueProfilePublishPath(
  pathParams: {| venue_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:venue_profile_id/publish', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesVenueProfileReviewsPath(
  pathParams: {| venue_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:venue_profile_id/reviews', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesVenueProfileTransferPath(
  pathParams: {| venue_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:venue_profile_id/transfer', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function profilesVenueProfileUnpublishPath(
  pathParams: {| venue_profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/venues/:venue_profile_id/unpublish',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function profilesVenueProfilesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/venues', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function publishViewingTimeAdminPriceRequestPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/admin/inquiries/:id/publish_viewing_time',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsBlobRepresentationPath(
  pathParams: {|
    signed_blob_id: Param,
    variation_key: Param,
    filename: Param
  |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/:filename',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsBlobRepresentationProxyPath(
  pathParams: {|
    signed_blob_id: Param,
    variation_key: Param,
    filename: Param
  |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/:filename',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsConductorInboundEmailPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/conductor/action_mailbox/inbound_emails/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsConductorInboundEmailIncineratePath(
  pathParams: {| inbound_email_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/conductor/action_mailbox/:inbound_email_id/incinerate',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsConductorInboundEmailReroutePath(
  pathParams: {| inbound_email_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/conductor/action_mailbox/:inbound_email_id/reroute',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsConductorInboundEmailSourcesPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/conductor/action_mailbox/inbound_emails/sources',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsConductorInboundEmailsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/conductor/action_mailbox/inbound_emails',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsDirectUploadsPath(queryParams?: QueryParams = {}): string {
  return format('/rails/active_storage/direct_uploads', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function railsDiskServicePath(
  pathParams: {| encoded_key: Param, filename: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/active_storage/disk/:encoded_key/:filename',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsInfoPath(queryParams?: QueryParams = {}): string {
  return format('/rails/info', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function railsInfoPropertiesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/rails/info/properties', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function railsInfoRoutesPath(queryParams?: QueryParams = {}): string {
  return format('/rails/info/routes', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function railsMailersPath(queryParams?: QueryParams = {}): string {
  return format('/rails/mailers', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function railsMailgunInboundEmailsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/action_mailbox/mailgun/inbound_emails/mime',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsMandrillInboundEmailsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/action_mailbox/mandrill/inbound_emails',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsMandrillInboundHealthCheckPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/action_mailbox/mandrill/inbound_emails',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsPostmarkInboundEmailsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/action_mailbox/postmark/inbound_emails',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsRelayInboundEmailsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/rails/action_mailbox/relay/inbound_emails', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function railsSendgridInboundEmailsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/action_mailbox/sendgrid/inbound_emails',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsServiceBlobPath(
  pathParams: {| signed_id: Param, filename: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/active_storage/blobs/redirect/:signed_id/:filename',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function railsServiceBlobProxyPath(
  pathParams: {| signed_id: Param, filename: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/active_storage/blobs/proxy/:signed_id/:filename',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function reactivateStaffEventRequestPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/event_requests/:id/reactivate',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function removeUserInvitationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/invitation/remove', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function reviewPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/reviews/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function robotsPath(queryParams?: QueryParams = {}): string {
  return format('/robots.txt', {}, queryParams, {
    translate: false,
    prependLocale: false
  });
}

export function rootPath(queryParams?: QueryParams = {}): string {
  return format('/', {}, queryParams, { translate: true, prependLocale: true });
}

export function rubyEventStoreBrowserAppPath(
  queryParams?: QueryParams = {}
): string {
  return format('/res', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function ruisrockLippujenVoittajaPath(
  queryParams?: QueryParams = {}
): string {
  return format('/ruisrock-lippujen-voittaja', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function searchSuppliersPath(
  pathParams: {| category: Param, sub_category: Param, location: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/s(/:category)(/:sub_category)(/:location)',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function sendCopyToAuthorPriceRequestPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/price_requests/:id/send-copy', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function sidekiqWebPath(queryParams?: QueryParams = {}): string {
  return format('/sidekiq', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function spamInquiriesStaffCommonSpamInquiriesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/spam_inquiries', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffCateringPath(queryParams?: QueryParams = {}): string {
  return format('/staff/catering', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffCateringLandingPagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/catering_landing_pages/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffCateringLandingPagesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/catering_landing_pages', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffCateringProfileTransferPath(
  pathParams: {| profile_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/catering/profiles/:profile_id/transfer',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffCateringServiceAreaCitiesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/catering/service_area_cities', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffCateringServiceAreaCityPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/catering/service_area_cities/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffCSVExportsPath(queryParams?: QueryParams = {}): string {
  return format('/staff/csv_exports', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffFeaturedVenuesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/featured_venues', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffImagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/images/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffImagesPath(queryParams?: QueryParams = {}): string {
  return format('/staff/images', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffInvoicePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/invoices/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffInvoicesPath(queryParams?: QueryParams = {}): string {
  return format('/staff/invoices', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffLandingPagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/landing_pages/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffLandingPagesPath(queryParams?: QueryParams = {}): string {
  return format('/staff/landing_pages', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffMetricsPath(queryParams?: QueryParams = {}): string {
  return format('/staff/metrics', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffOrganizationPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/organizations/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffOrganizationContractPath(
  pathParams: {| organization_id: Param, id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/contracts/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffOrganizationContractsPath(
  pathParams: {| organization_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/contracts',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffOrganizationPipedriveStatusPath(
  pathParams: {| organization_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/pipedrive_status',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffOrganizationProfileAnnouncementsPath(
  pathParams: {| organization_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/profile_announcements',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffOrganizationServiceFeePath(
  pathParams: {| organization_id: Param, id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/service_fees/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffOrganizationServiceFeesPath(
  pathParams: {| organization_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/organizations/:organization_id/service_fees',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffOrganizationsPath(queryParams?: QueryParams = {}): string {
  return format('/staff/organizations', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffPriceRequestsPath(queryParams?: QueryParams = {}): string {
  return format('/staff/price_requests', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffProspectsPath(queryParams?: QueryParams = {}): string {
  return format('/staff/prospects', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffReferralCookiePath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/referral_cookie', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffReferralReportingGeneratedReportPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/referral_reporting/generated_reports/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffReferralReportingGeneratedReportsPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/referral_reporting/generated_reports',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffReferralReportingReportPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/referral_reporting/reports/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffReferralReportingReportsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/referral_reporting/reports', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffReferralReportingRootPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/referral_reporting', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffResendMessagePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/resend_messages/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffSearchSupplierSearchExcludedProfilePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_excluded_profiles/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffSearchSupplierSearchTopLevelCategoriesPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffSearchSupplierSearchTopLevelCategoryPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffSearchSupplierSearchTopLevelCategorySubCategoriesPath(
  pathParams: {| supplier_search_top_level_category_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/:supplier_search_top_level_category_id/sub_categories',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffSearchSupplierSearchTopLevelCategorySubCategoryPath(
  pathParams: {| supplier_search_top_level_category_id: Param, id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/:supplier_search_top_level_category_id/sub_categories/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffSiteManagementPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/site_management', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffSiteManagementEventTypePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/site_management/event_types/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffSiteManagementEventTypesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/site_management/event_types', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffSiteManagementVenueServicePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/site_management/venue_services/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffSiteManagementVenueServicesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/site_management/venue_services', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffSiteManagementVenueTypePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/site_management/venue_types/:id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffSiteManagementVenueTypesPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/site_management/venue_types', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffSpamReviewPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/spam_reviews/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffSpamReviewsPath(queryParams?: QueryParams = {}): string {
  return format('/staff/spam_reviews', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffSpamReviewsRecoverPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/spam_reviews/recover', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffUserPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/users/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffUsersPath(queryParams?: QueryParams = {}): string {
  return format('/staff/users', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffVenuePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/venues/:id', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffVenueFeaturedEventTypesPath(
  pathParams: {| venue_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/venues/:venue_id/featured_event_types',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffVenueRelevanceScoresPath(
  pathParams: {| venue_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/venues/:venue_id/relevance_scores',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function staffVenuesPath(queryParams?: QueryParams = {}): string {
  return format('/staff/venues', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffWorkbenchesPath(queryParams?: QueryParams = {}): string {
  return format('/staff/workbenches', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffWorkbenchesRelevanceVisualizationPath(
  queryParams?: QueryParams = {}
): string {
  return format('/staff/workbenches/relevance_visualization', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function staffWorkbenchesSimilarityVisualizationPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/workbenches/similarity_visualization',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function styleguidePath(queryParams?: QueryParams = {}): string {
  return format('/styleguide', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function succeededOutcomesCommonOutcomesEventCandidatePath(
  pathParams: {| token: Param, outcome_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/event_feedback/:token/succeeded/:outcome_id',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function swapStaffSearchSupplierSearchTopLevelCategoriesPath(
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/swap',
    {},
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function swapStaffSearchSupplierSearchTopLevelCategorySubCategoriesPath(
  pathParams: {| supplier_search_top_level_category_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/supplier_search_top_level_categories/:supplier_search_top_level_category_id/sub_categories/swap',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function tentativeOutcomesCommonOutcomesEventCandidatePath(
  pathParams: {| token: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/event_feedback/:token/tentative', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function termsOfUsePath(queryParams?: QueryParams = {}): string {
  return format('/terms-of-use', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function thanksPriceRequestsPath(
  queryParams?: QueryParams = {}
): string {
  return format('/price_requests/thanks', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function toggleBookingInquiryStaffVenuePath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/staff/venues/:id/toggle_booking_inquiry',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function transferStaffOrganizationPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/staff/organizations/:id/transfer', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function turkuPath(queryParams?: QueryParams = {}): string {
  return format('/turku', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function unknownOutcomesCommonOutcomesEventCandidatePath(
  pathParams: {| token: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/event_feedback/:token/unknown', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function updateNoteListPath(
  pathParams: {| id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/lists/:id/update_note', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function updateRailsDiskServicePath(
  pathParams: {| encoded_token: Param |},
  queryParams?: QueryParams = {}
): string {
  return format(
    '/rails/active_storage/disk/:encoded_token',
    pathParams,
    queryParams,
    { translate: true, prependLocale: true }
  );
}

export function userInvitationPath(queryParams?: QueryParams = {}): string {
  return format('/invitation', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function userPasswordPath(queryParams?: QueryParams = {}): string {
  return format('/password', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function userRegistrationPath(queryParams?: QueryParams = {}): string {
  return format('/', {}, queryParams, { translate: true, prependLocale: true });
}

export function userSessionPath(queryParams?: QueryParams = {}): string {
  return format('/login', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function venueReviewsPath(
  pathParams: {| venue_id: Param |},
  queryParams?: QueryParams = {}
): string {
  return format('/venues/:venue_id/reviews', pathParams, queryParams, {
    translate: true,
    prependLocale: true
  });
}

export function venuesPath(queryParams?: QueryParams = {}): string {
  return format('/venues', {}, queryParams, {
    translate: true,
    prependLocale: true
  });
}
