// @flow

import * as React from 'react';

import type { ExperiencePackage, PackageSelectComponent } from 'packages/types';

import PackageAccordion from './common/PackageAccordion';
import PackageDetails from './common/PackageDetails';
import PackageList from './common/PackageList';
import PackageSummary from './common/PackageSummary';

export type Props = {|
  packages: Array<ExperiencePackage>,
  onPackageViewed: ExperiencePackage => void,
  PackageSelectComponent?: PackageSelectComponent
|};

export default function ExperiencePackageList(props: Props): React.Node {
  return (
    <PackageList
      packages={props.packages}
      package={pkg => (
        <PackageAccordion
          key={pkg.id}
          onPackageViewed={() => props.onPackageViewed(pkg)}
          summary={<PackageSummary pkg={pkg} />}
          details={
            <PackageDetails
              package={pkg}
              PackageSelectComponent={props.PackageSelectComponent}
            />
          }
        />
      )}
    />
  );
}
