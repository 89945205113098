// @flow

import {
  type Visitor,
  getVisitorDetails,
  saveVisitorDetails
} from 'common/visitorDetails/persistence';

export function persistFieldsIfNeeded() {
  if (shouldPersistTemplate()) {
    persistFields();
  }
}

// TODO Restore persisted values in corresponding react components instead
export const restorePersistedFieldValues = () => {
  const { eventAt, otherDatesPossible, name, email, phone, company } =
    getVisitorDetails();

  setFieldValue(getEventAtInput(), eventAt);
  setFieldValue(getInquiryInputElement('price_request[author_name]'), name);
  setFieldValue(getInquiryInputElement('price_request[author_email]'), email);
  setFieldValue(getInquiryInputElement('price_request[author_phone]'), phone);
  setFieldValue(getInquiryInputElement('price_request[company]'), company);
  setCheckedValue(
    getInquiryInputElement('other_dates_possible'),
    otherDatesPossible
  );
};

function setFieldValue(
  element: HTMLInputElement | HTMLTextAreaElement | null,
  value: string | null
) {
  if (element && value) {
    element.value = value;
  }
}
function setCheckedValue(
  element: HTMLInputElement | null,
  value: boolean | null
) {
  if (element && typeof value === 'boolean') {
    element.checked = value;
  }
}

function shouldPersistTemplate(): boolean {
  const persistCheckbox = getInquiryInputElement(
    'persist_price_request_template'
  );
  if (!persistCheckbox) {
    return false;
  }
  return persistCheckbox.checked;
}

function persistFields() {
  const detailsToStore = buildPersistedFields([
    ['eventAt', getEventAtInput()],
    ['name', getInquiryInputElement('price_request[author_name]')],
    ['email', getInquiryInputElement('price_request[author_email]')],
    ['phone', getInquiryInputElement('price_request[author_phone]')],
    ['company', getInquiryInputElement('price_request[company]')],
    ['inquiryBody', getInquiryTextAreaElement('price_request[body]')]
  ]);
  const otherDatesPossibleEl = getInquiryInputElement('other_dates_possible');
  if (
    otherDatesPossibleEl &&
    otherDatesPossibleEl instanceof HTMLInputElement
  ) {
    detailsToStore.otherDatesPossible = otherDatesPossibleEl.checked;
  }
  saveVisitorDetails(detailsToStore);
}

function buildPersistedFields(
  elementValuePairs: Array<
    [$Keys<Visitor>, HTMLInputElement | HTMLTextAreaElement | null]
  >
): Partial<Visitor> {
  const detailsToStore: Partial<Visitor> = {};
  elementValuePairs.forEach(([key, element]) => {
    if (element) {
      // This function should probably be avoided and the same feature done with better type safety.
      // $FlowFixMe[incompatible-type] -- Suppressed when converting from $Shape<> to Partial<>. Delete this comment to view the error.
      detailsToStore[key] = element.value;
    }
  });
  return detailsToStore;
}

function getInquiryInputElement(name: string): null | HTMLInputElement {
  const inputEl = document.querySelector(`input[name="${name}"]`);
  if (inputEl && inputEl instanceof HTMLInputElement) {
    return inputEl;
  }
  return null;
}

function getInquiryTextAreaElement(name: string): null | HTMLTextAreaElement {
  const textAreaEl = document.querySelector(`textarea[name="${name}"]`);
  if (textAreaEl && textAreaEl instanceof HTMLTextAreaElement) {
    return textAreaEl;
  }
  return null;
}

// Event at field is special, as the actual value is stored inside a <input type="hidden">
// and we want to get access to the visible element instead
function getEventAtInput(): null | HTMLInputElement {
  const eventAtEl = document.getElementById('event-at-input');
  if (eventAtEl && eventAtEl instanceof HTMLInputElement) {
    return eventAtEl;
  }
  return null;
}
