// @flow

import * as React from 'react';

import styles from './LegalNotice.scss';

import { termsOfUsePath, privacyPolicyPath } from 'routes';

export default function LegalNotice(): React.Node {
  return (
    <section
      className={styles.legalNotice}
      dangerouslySetInnerHTML={{
        __html: I18n.interpolate(__('inquiry_form.accept_terms'), {
          terms_link: `<a href="${termsOfUsePath()}" target="_blank">${__(
            'inquiry_form.terms_and_conditions'
          )}</a>`,
          privacy_policy_link: `<a href="${privacyPolicyPath()}" target="_blank">${__(
            'inquiry_form.privacy_policy'
          )}</a>`
        })
      }}
    />
  );
}
