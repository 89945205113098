// @flow

import * as React from 'react';

import groupBy from 'lodash/groupBy';

import type {
  MeetingPackage,
  CelebrationPackage,
  VenuePackage
} from 'packages/types';

import PackageGroup from './PackageGroup';

import sortPackages from 'packages/common/sorters/sortPackages';

type PackageWithCategory = MeetingPackage | CelebrationPackage | VenuePackage;

type Translations = { [key: string]: string, ... };

type Props<Pkg: PackageWithCategory> = {|
  packages: Array<Pkg>,
  categories: Array<$PropertyType<Pkg, 'category'>>,
  labelsForCategory: Translations,
  package: Pkg => React.Node
|};

function dynamicTranslation(translations: Translations, key: string): string {
  if (translations[key]) {
    return translations[key];
  }
  throw new Error(
    `Expected a translation object with key '${key}', but key was empty`
  );
}

function PackagesByCategory<Pkg: PackageWithCategory>(
  props: Props<Pkg>
): React.Node {
  if (!props.packages) {
    return null;
  }

  const packagesByCategory = groupBy(props.packages, pkg => pkg.category);
  const packageGroupsInPredeterminedOrder = props.categories
    .filter(category => !!packagesByCategory[category])
    .map(category => ({
      category,
      packages: packagesByCategory[category]
    }));

  return (
    <div>
      {packageGroupsInPredeterminedOrder.map(({ category, packages }) => (
        <PackageGroup
          key={category}
          title={dynamicTranslation(
            props.labelsForCategory,
            category.toLowerCase()
          )}
        >
          {sortPackages(packages).map(props.package)}
        </PackageGroup>
      ))}
    </div>
  );
}

export default PackagesByCategory;
