// @flow

import * as React from 'react';
import classnames from 'classnames';
import impossible from 'common/types/impossible';

import FAIcon from 'common/components/FAIcon';

import styles from './Checklist.scss';

export function List(props: {
  children: React.Node,
  className?: string,
  ...
}): React.Node {
  return (
    <ul className={classnames(styles.checklist, props.className)}>
      {props.children}
    </ul>
  );
}

type ItemProps = {
  children: React.Node,
  className?: string,
  type?: 'success' | 'primary',
  ...
};

export function Item(props: ItemProps): React.Node {
  const itemClassName = classnames(styles.item, props.className);

  const type = props.type || 'success';

  return (
    <li className={itemClassName}>
      <FAIcon name="check" className={iconClassName(type)} />
      {props.children}
    </li>
  );
}

function iconClassName(type: 'primary' | 'success') {
  switch (type) {
    case 'success':
      return styles.checkSuccess;
    case 'primary':
      return styles.checkPrimary;
    default:
      return impossible(type);
  }
}
