// @flow

import type { Duration } from 'packages/types';

export default function summarizeDuration(d: Duration): string | null {
  const hasMin = typeof d.min === 'number';
  const hasMax = typeof d.max === 'number';

  switch (true) {
    case hasMin && hasMax:
      return I18n.t('meeting_packages.summary.duration.min_and_max', d);
    case hasMin:
      return I18n.t('meeting_packages.summary.duration.min', d);
    case hasMax:
      return I18n.t('meeting_packages.summary.duration.max', d);
    default:
      return null;
  }
}
