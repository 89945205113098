// @flow

import * as React from 'react';
import values from 'lodash/values';

import { MeetingPackageCategoryEnum } from 'packages/enums';
import type {
  MeetingPackage,
  Service,
  PackageSelectComponent
} from 'packages/types';

import PackageAccordion from './common/PackageAccordion';
import PackagesByCategory from './common/PackagesByCategory';
import PackageSummary from './common/PackageSummary';
import PackageDetailsWithServices from './common/PackageDetailsWithServices';

export type Props = {|
  packages: Array<MeetingPackage>,
  allServices: Array<Service>,
  onPackageViewed: MeetingPackage => void,
  PackageSelectComponent?: PackageSelectComponent
|};

export default function MeetingPackageList(props: Props): React.Node {
  return (
    <PackagesByCategory
      labelsForCategory={__obj('meeting_packages.categories')}
      categories={values(MeetingPackageCategoryEnum)}
      packages={props.packages}
      package={pkg => (
        <PackageAccordion
          key={pkg.id}
          onPackageViewed={() => props.onPackageViewed(pkg)}
          summary={<PackageSummary pkg={pkg} />}
          details={
            <PackageDetailsWithServices
              PackageSelectComponent={props.PackageSelectComponent}
              allServices={props.allServices}
              package={pkg}
            />
          }
        />
      )}
    />
  );
}
