// @flow

import values from 'lodash/values';

import type {
  MeetingPackageCategory,
  CelebrationPackageCategory,
  VenuePackageCategory,
  PackageType
} from 'packages/types';

export const PackageTypeEnum = {
  MEETING_PACKAGE: 'MEETING_PACKAGE',
  CELEBRATION_PACKAGE: 'CELEBRATION_PACKAGE',
  EXPERIENCE_PACKAGE: 'EXPERIENCE_PACKAGE',
  VENUE_PACKAGE: 'VENUE_PACKAGE'
};

export function toPackageType(s: string): PackageType {
  if (values(PackageTypeEnum).includes(s)) {
    return ((s: any): PackageType);
  }
  throw new Error(`No such type: '${s}'`);
}

// NOTE: If you i18n these, make sure to change celebration package editor feature specs, too.
export const PackageTypeLabels: { [type: PackageType]: string, ... } = {
  [PackageTypeEnum.MEETING_PACKAGE]: 'Meeting packages',
  [PackageTypeEnum.CELEBRATION_PACKAGE]: 'Celebration packages',
  [PackageTypeEnum.EXPERIENCE_PACKAGE]: 'Experience packages',
  [PackageTypeEnum.VENUE_PACKAGE]: 'Venue packages'
};

export const MeetingPackageCategoryEnum = {
  HALF_DAY: 'half_day',
  WHOLE_DAY: 'whole_day',
  TWO_DAY: 'two_day',
  SHORT: 'short',
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  EVENING: 'evening'
};

export function toMeetingPackageCategory(s: string): MeetingPackageCategory {
  if (values(MeetingPackageCategoryEnum).includes(s)) {
    return ((s: any): MeetingPackageCategory);
  }
  throw new Error(`No such category: '${s}'`);
}

export const MeetingPackageCategoryLabels: {
  [category: MeetingPackageCategory]: string,
  ...
} = {
  [MeetingPackageCategoryEnum.HALF_DAY]: __(
    'meeting_packages.categories.half_day'
  ),
  [MeetingPackageCategoryEnum.WHOLE_DAY]: __(
    'meeting_packages.categories.whole_day'
  ),
  [MeetingPackageCategoryEnum.TWO_DAY]: __(
    'meeting_packages.categories.two_day'
  ),
  [MeetingPackageCategoryEnum.SHORT]: __('meeting_packages.categories.short'),
  [MeetingPackageCategoryEnum.BREAKFAST]: __(
    'meeting_packages.categories.breakfast'
  ),
  [MeetingPackageCategoryEnum.LUNCH]: __('meeting_packages.categories.lunch'),
  [MeetingPackageCategoryEnum.EVENING]: __(
    'meeting_packages.categories.evening'
  )
};

export const CelebrationPackageCategoryEnum = {
  PARTY: 'party',
  WEDDING: 'wedding',
  PRE_CHRISTMAS_PARTY: 'pre_christmas_party',
  SAUNA: 'sauna',
  DINNER: 'dinner',
  MISC: 'misc'
};

export function toCelebrationPackageCategory(
  s: string
): CelebrationPackageCategory {
  if (values(CelebrationPackageCategoryEnum).includes(s)) {
    return ((s: any): CelebrationPackageCategory);
  }
  throw new Error(`No such category: '${s}'`);
}

export const CelebrationPackageCategoryLabels: {
  [category: CelebrationPackageCategory]: string,
  ...
} = {
  [CelebrationPackageCategoryEnum.PARTY]: __(
    'celebration_packages.categories.party'
  ),
  [CelebrationPackageCategoryEnum.WEDDING]: __(
    'celebration_packages.categories.wedding'
  ),
  [CelebrationPackageCategoryEnum.PRE_CHRISTMAS_PARTY]: __(
    'celebration_packages.categories.pre_christmas_party'
  ),
  [CelebrationPackageCategoryEnum.SAUNA]: __(
    'celebration_packages.categories.sauna'
  ),
  [CelebrationPackageCategoryEnum.DINNER]: __(
    'celebration_packages.categories.dinner'
  ),
  [CelebrationPackageCategoryEnum.MISC]: __(
    'celebration_packages.categories.misc'
  )
};

export const VenuePackageCategoryEnum = {
  HOUR_BASED: 'hour_based',
  DAY_SLOT: 'day_slot',
  EVENING_SLOT: 'evening_slot',
  HALF_DAY: 'half_day',
  WHOLE_DAY: 'whole_day',
  WHOLE_WEEKEND: 'whole_weekend',
  LONGER: 'longer'
};

export function toVenuePackageCategory(s: string): VenuePackageCategory {
  if (values(VenuePackageCategoryEnum).includes(s)) {
    return ((s: any): VenuePackageCategory);
  }
  throw new Error(`No such category: '${s}'`);
}

export const VenuePackageCategoryLabels: {
  [category: VenuePackageCategory]: string,
  ...
} = {
  [VenuePackageCategoryEnum.HOUR_BASED]: __(
    'venue_packages.categories.hour_based'
  ),
  [VenuePackageCategoryEnum.DAY_SLOT]: __('venue_packages.categories.day_slot'),
  [VenuePackageCategoryEnum.EVENING_SLOT]: __(
    'venue_packages.categories.evening_slot'
  ),
  [VenuePackageCategoryEnum.HALF_DAY]: __('venue_packages.categories.half_day'),
  [VenuePackageCategoryEnum.WHOLE_DAY]: __(
    'venue_packages.categories.whole_day'
  ),
  [VenuePackageCategoryEnum.WHOLE_WEEKEND]: __(
    'venue_packages.categories.whole_weekend'
  ),
  [VenuePackageCategoryEnum.LONGER]: __('venue_packages.categories.longer')
};
