// @flow

import Honeybadger from '@honeybadger-io/js';
import impossible from 'common/types/impossible';
import scrollIntoView from 'scroll-into-view';

import {
  persistFieldsIfNeeded,
  restorePersistedFieldValues
} from './priceRequestForm/persistenceHooks';

let inquiryFormSubmitHooks = [];
export function hookToInquiryFormSubmit(callback: () => 'allow' | 'deny') {
  inquiryFormSubmitHooks.push(callback);
}
export function removeInquiryFormSubmitHook(callback: Function) {
  inquiryFormSubmitHooks = inquiryFormSubmitHooks.filter(cb => cb !== callback);
}
let inquiryFormWillSubmitHooks = [];
export function hookToInquiryFormWillSubmit(callback: () => void) {
  inquiryFormWillSubmitHooks.push(callback);
}
export function removeInquiryFormWillSubmitHook(callback: Function) {
  inquiryFormWillSubmitHooks = inquiryFormWillSubmitHooks.filter(
    cb => cb !== callback
  );
}

hookToInquiryFormWillSubmit(persistFieldsIfNeeded);

function initSubmitHook(prFormElement: HTMLElement) {
  function handleInquiryFormSubmit(evt: Event) {
    let submitAllowed = true;
    inquiryFormSubmitHooks.forEach(cb => {
      const returnValue = cb();
      switch (returnValue) {
        case 'allow':
          // All good, this callback didn't deny the submit of the PR form.
          // However, all we need is one callback to deny submitting so we should not
          // reset the boolean flag here
          break;
        case 'deny':
          // We're denied from submitting the PR form at all.
          submitAllowed = false;
          break;
        default:
          impossible(returnValue);
      }
    });

    if (!submitAllowed) {
      evt.preventDefault();
      evt.stopPropagation();
      scrollIntoView(prFormElement);
    } else if (!evt.defaultPrevented) {
      // Foundation Abide can have prevented the form submit, so only if the
      // event.defaultPrevented is false, will the user actually submit the form.

      inquiryFormWillSubmitHooks.map(callback => {
        // No errors should prevent the form to be submitted at this point.
        // Better to be safe than sorry, especially if the hooks were to break
        // for some esoteric browsers that we don't yet know about.
        try {
          callback();
        } catch (err) {
          Honeybadger.notify(err);
        }
      });
    }
  }

  // Ensure this listener is added after any Foundation listeners have been attached,
  // so that Foundation Abide has had the chance to prevent submit before our hooks are ran.
  setTimeout(() => {
    prFormElement.addEventListener('submit', handleInquiryFormSubmit);
  });
}

export default () => {
  const prFormElement = document.getElementById('price-request-form');
  if (!prFormElement) {
    return;
  }

  restorePersistedFieldValues();
  initSubmitHook(prFormElement);
};
