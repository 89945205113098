// @flow

import type { Capacity } from 'packages/types';

export default function summarizeCapacity(c: Capacity): string | null {
  const hasMin = typeof c.min === 'number';
  const hasMax = typeof c.max === 'number';

  switch (true) {
    case hasMin && hasMax:
      return I18n.t('meeting_packages.summary.capacity.min_and_max', c);
    case hasMin:
      return I18n.t('meeting_packages.summary.capacity.min', c);
    case hasMax:
      return I18n.t('meeting_packages.summary.capacity.max', c);
    default:
      return null;
  }
}
