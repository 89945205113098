// @flow

import type { LocalizedPricing, TranslatedPricing } from './pricingTypes';

export default function toTranslatedPrices(
  p: LocalizedPricing
): TranslatedPricing {
  return {
    additionalRentPerHour: p.additionalRentPerHour
      ? I18n.t('meeting_packages.details.pricing.additional_rent_per_hour', p)
      : null,
    genericFixed: p.genericFixed
      ? I18n.t('meeting_packages.details.pricing.generic_fixed', p)
      : null,
    perPerson: p.perPerson
      ? I18n.t('meeting_packages.details.pricing.per_person', p)
      : null,
    rentFixed: p.rentFixed
      ? I18n.t('meeting_packages.details.pricing.rent_fixed', p)
      : null,
    rentPerHour: p.rentPerHour
      ? I18n.t('meeting_packages.details.pricing.rent_per_hour', p)
      : null,
    salesGuarantee: p.salesGuarantee
      ? I18n.t('meeting_packages.details.pricing.sales_guarantee', p)
      : null,
    salesGuaranteePerHour: p.salesGuaranteePerHour
      ? I18n.t('meeting_packages.details.pricing.sales_guarantee_per_hour', p)
      : null
  };
}
