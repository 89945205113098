// @flow

import * as React from 'react';
import FontAwesomeIcon, { check } from 'common/components/FontAwesomeIcon';
import styles from './EmailMarketingConsentCheckbox.scss';
import { nullable, boolean } from 'common/json/extract';
import type { JSONValue } from 'common/types';
import { inputTarget } from 'common/eventTargets';

type Props = {|
  name: string
|};
export default function EmailMarketingConsentCheckbox({
  name
}: Props): React.Node {
  const [checkboxValue, setCheckboxValue] = React.useState<null | boolean>(
    false
  );

  React.useEffect(() => {
    const value = getCheckboxValueFromLocalStorage();

    setCheckboxValue(value);
  }, []);

  function updateConsentCheckbox(value: boolean) {
    setCheckboxValue(value);
    setCheckboxValueToLocalStorage(value);
  }

  return (
    <label className={styles.label}>
      <input name={name} value="0" type="hidden" />
      <input
        name={name}
        value="1"
        type="checkbox"
        checked={checkboxValue}
        onChange={evt => updateConsentCheckbox(inputTarget(evt).checked)}
      />
      <FontAwesomeIcon className={styles.icon} icon={check} />
      <span
        className={styles.labelText}
        dangerouslySetInnerHTML={{
          __html: I18n.t('inquiry_form.email_marketing_consent')
        }}
      ></span>
    </label>
  );
}

type CheckboxValueInLocalStorage = null | boolean;
const DATA_KEY = 'email_marketing_consent';

const extractCheckboxValue: JSONValue => CheckboxValueInLocalStorage =
  nullable(boolean);

function setCheckboxValueToLocalStorage(value: boolean): void {
  try {
    localStorage.setItem(DATA_KEY, JSON.stringify(value));
  } catch (e) {
    // We end up here when the user local storage quota is full or user private browses on mobile Safari.
  }
}

function getCheckboxValueFromLocalStorage(): CheckboxValueInLocalStorage {
  let parsedData = null;
  try {
    const data = localStorage.getItem(DATA_KEY);
    if (data) {
      parsedData = JSON.parse(data);
    }
  } catch (_err) {
    // We don't care if localStorage.getItem or JSON.parse throws error
  }
  return extractCheckboxValue(parsedData);
}
