// @flow

import * as React from 'react';

import classNames from 'classnames';

import summarizeCapacity from './helpers/summarizeCapacity';
import summarizePricing from './helpers/summarizePricing';
import summarizeDuration from './helpers/summarizeDuration';

import type { Capacity, Pricing, Duration } from 'packages/types';

import styles from './PackageSummary.scss';

export type Props = {|
  pkg: {
    name: string,
    duration: Duration,
    capacity: Capacity,
    pricing: Pricing,
    // We don't care if there are more props and we promise not to write to those
    +[string: any]: mixed
  }
|};

export default function PackageSummary(props: Props): React.Node {
  return (
    <div>
      <SummaryName name={props.pkg.name} />
      <SummaryDuration duration={props.pkg.duration} />
      <SummaryCapacity capacity={props.pkg.capacity} />
      <SummaryPricing pricing={props.pkg.pricing} />
    </div>
  );
}

const nbsp = '\u00A0';

function SummaryName(props: {| name: string |}) {
  return <div className={classNames(styles.name)}>{props.name || nbsp}</div>;
}

function SummaryDuration(props: {| duration: Duration |}) {
  return (
    <div className={classNames(styles.duration)}>
      {summarizeDuration(props.duration) || nbsp}
    </div>
  );
}

function SummaryCapacity(props: {| capacity: Capacity |}) {
  return (
    <div className={styles.capacity}>
      {summarizeCapacity(props.capacity) || nbsp}
    </div>
  );
}

function SummaryPricing(props: {| pricing: Pricing |}) {
  return (
    <div className={styles.pricing}>
      {summarizePricing(props.pricing) || nbsp}
    </div>
  );
}
