// @flow

import * as React from 'react';

import type { Package } from 'packages/types';

import sortPackages from 'packages/common/sorters/sortPackages';

import PackageSection from './PackageSection';

type Props<Pkg> = {|
  packages: Array<Pkg>,
  package: Pkg => React.Node
|};

export default function PackageList<Pkg: Package>(
  props: Props<Pkg>
): React.Node {
  return (
    <PackageSection>
      {sortPackages(props.packages).map(props.package)}
    </PackageSection>
  );
}
