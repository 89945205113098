// @flow

export const isBlank = (s: string): boolean => s === '';

export {
  buttonTarget,
  inputTarget,
  textareaTarget,
  selectTarget,
  eventTargetValue
} from 'common/eventTargets';

export { default as toLocalizedPrices } from 'packages/utils/pricing/toLocalizedPrices';
export type { LocalizedPricing } from 'packages/utils/pricing/pricingTypes';
export { default as pricesToText } from 'packages/utils/pricing/pricesToText';
export { default as vatText } from 'packages/utils/pricing/vatText';
