// @flow

import * as React from 'react';
import values from 'lodash/values';

import { VenuePackageCategoryEnum } from 'packages/enums';
import type {
  VenuePackage,
  PackageSelectComponent,
  Service
} from 'packages/types';

import PackageAccordion from './common/PackageAccordion';
import PackagesByCategory from './common/PackagesByCategory';
import PackageSummary from './common/PackageSummary';

import PackageDetailsWithServices from './common/PackageDetailsWithServices';

export type Props = {|
  packages: Array<VenuePackage>,
  allServices: Array<Service>,
  onPackageViewed: VenuePackage => void,
  PackageSelectComponent?: PackageSelectComponent
|};

export default function VenuePackageList(props: Props): React.Node {
  return (
    <PackagesByCategory
      labelsForCategory={__obj('venue_packages.categories')}
      categories={values(VenuePackageCategoryEnum)}
      packages={props.packages}
      package={pkg => (
        <PackageAccordion
          key={pkg.id}
          onPackageViewed={() => props.onPackageViewed(pkg)}
          summary={<PackageSummary pkg={pkg} />}
          details={
            <PackageDetailsWithServices
              package={pkg}
              PackageSelectComponent={props.PackageSelectComponent}
              allServices={props.allServices}
            />
          }
        />
      )}
    />
  );
}
