// @flow

import * as React from 'react';

import styles from './PackageSection.scss';

export default function PackageSection(props: {
  children: React.Node,
  ...
}): React.Node {
  return <section className={styles.container}>{props.children}</section>;
}
