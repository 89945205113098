// @flow

import * as React from 'react';

import type {
  PackageSelectComponent as PackageSelectComponentType,
  Package
} from 'packages/types';

import styles from './PackageDetails.scss';

import {
  PackageName,
  PackageCapacity,
  PackageDuration,
  PackageAvailability,
  PackagePrice
} from './PackageAttributes';

import { PackageDescription } from './PackageDescription';

type Props = {|
  selectable?: boolean,
  package: Package,
  PackageSelectComponent?: PackageSelectComponentType
|};

export default function PackageDetails(props: Props): React.Node {
  const { package: pkg, PackageSelectComponent } = props;

  return (
    <div className={styles['details']}>
      <PackageName name={pkg.name} />
      <div className={styles['details__info-panel']}>
        <PackageDescription description={pkg.description} />
        <div className={styles['details__spec-container']}>
          <PackageAvailability availability={pkg.availability} />
          <PackageDuration duration={pkg.duration} />
          <PackageCapacity capacity={pkg.capacity} />
          <PackagePrice pricing={pkg.pricing} />
        </div>
        {PackageSelectComponent ? <PackageSelectComponent pkg={pkg} /> : null}
      </div>
    </div>
  );
}
