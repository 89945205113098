// @flow
import * as React from 'react';

import Accordion from 'common/components/Accordion';

import PackageAccordionHeading from './PackageAccordionHeading';

import styles from './PackageAccordion.scss';

type Props = {|
  detailsInitiallyVisible?: boolean,
  onPackageViewed?: () => void,
  details: React.Node,
  summary: React.Node
|};

export default function PackageAccordion(props: Props): React.Node {
  const detailsInitiallyVisible = props.detailsInitiallyVisible || false;

  const { onPackageViewed } = props;

  const visibleCallback = (visible: boolean) => {
    if (visible && onPackageViewed) {
      onPackageViewed();
    }
  };

  return (
    <div className={styles.container}>
      <Accordion
        openByDefault={detailsInitiallyVisible}
        onToggle={visibleCallback}
        heading={({ isOpen }) => (
          <PackageAccordionHeading isOpen={isOpen}>
            {props.summary}
          </PackageAccordionHeading>
        )}
        body={props.details}
      />
    </div>
  );
}
