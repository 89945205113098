// @flow

import * as React from 'react';
import keyBy from 'lodash/keyBy';

import { List, Item } from 'common/components/Checklist';

import sortServices from 'packages/common/sorters/sortServices';

import type { Service } from 'packages/types';

import styles from './ServiceList.scss';

type ServiceId = $PropertyType<Service, 'id'>;

type Props = {
  allServices: Array<Service>,
  includedServiceIds: Array<ServiceId>,
  ...
};

function ServiceList(props: Props): React.Node {
  const allServicesById = keyBy(props.allServices, service => service.id);
  const services = props.includedServiceIds.map(id => allServicesById[id]);
  const sortedServices = sortServices(services);
  const includedServiceNames = sortedServices.map(service => service.name);

  return (
    <List className={styles['service-list']}>
      <li className={styles['service']}>
        {__('meeting_packages.details.services')}:
      </li>
      {includedServiceNames.map(serviceName => (
        <Item className={styles['service']} key={serviceName}>
          {serviceName}
        </Item>
      ))}
    </List>
  );
}

export default ServiceList;
