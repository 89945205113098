// @flow

import * as React from 'react';

import type {
  PackageSelectComponent as PackageSelectComponentType,
  MeetingPackage,
  VenuePackage,
  Service
} from 'packages/types';

import styles from './PackageDetailsWithServices.scss';

import ServiceList from './ServiceList';

import {
  PackageName,
  PackageCapacity,
  PackageDuration,
  PackageAvailability,
  PackagePrice
} from './PackageAttributes';

import { PackageDescription } from './PackageDescription';

type Props<Pkg> = {|
  PackageSelectComponent?: PackageSelectComponentType,
  package: Pkg,
  allServices: Array<Service>
|};

type PackageWithServices = MeetingPackage | VenuePackage;

export default function PackageDetailsWithServices<Pkg: PackageWithServices>(
  props: Props<Pkg>
): React.Node {
  const { package: pkg, PackageSelectComponent, allServices } = props;

  return (
    <div className={styles['details']}>
      <PackageName name={pkg.name} />
      <ServiceList
        allServices={allServices}
        includedServiceIds={pkg.serviceIds}
      />
      <div className={styles['details__info-panel']}>
        <PackageDescription description={pkg.description} />
        <div className={styles['details__spec-container']}>
          <PackageAvailability availability={pkg.availability} />
          <PackageDuration duration={pkg.duration} />
          <PackageCapacity capacity={pkg.capacity} />
          <PackagePrice pricing={pkg.pricing} />
        </div>
        {PackageSelectComponent ? <PackageSelectComponent pkg={pkg} /> : null}
      </div>
    </div>
  );
}
