// @flow

import * as React from 'react';

import AccordionStateIcon from 'common/components/accordion/AccordionStateIcon';

import styles from './PackageAccordionHeading.scss';

const PackageAccordionHeading = (props: {
  children: React.Node,
  isOpen: boolean,
  ...
}): React.Node => (
  <div className={styles.container}>
    <div className={styles.info}>{props.children}</div>
    <div className={styles.accordionState}>
      <AccordionStateIcon isOpen={props.isOpen} />
    </div>
  </div>
);

export default PackageAccordionHeading;
