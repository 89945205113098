// @flow

import * as React from 'react';
import values from 'lodash/values';

import { CelebrationPackageCategoryEnum } from 'packages/enums';
import type {
  CelebrationPackage,
  PackageSelectComponent
} from 'packages/types';

import PackageAccordion from './common/PackageAccordion';
import PackageDetails from './common/PackageDetails';
import PackagesByCategory from './common/PackagesByCategory';
import PackageSummary from './common/PackageSummary';

export type Props = {|
  packages: Array<CelebrationPackage>,
  onPackageViewed: CelebrationPackage => void,
  PackageSelectComponent?: PackageSelectComponent
|};

export default function CelebrationPackageList(props: Props): React.Node {
  return (
    <PackagesByCategory
      labelsForCategory={__obj('celebration_packages.categories')}
      categories={values(CelebrationPackageCategoryEnum)}
      packages={props.packages}
      package={pkg => (
        <PackageAccordion
          key={pkg.id}
          onPackageViewed={() => props.onPackageViewed(pkg)}
          summary={<PackageSummary pkg={pkg} />}
          details={
            <PackageDetails
              package={pkg}
              PackageSelectComponent={props.PackageSelectComponent}
            />
          }
        />
      )}
    />
  );
}
