// @flow

import sortBy from 'lodash/sortBy';
import type { Package } from 'packages/types';

export default function sortPackages<PkgType: Package>(
  pkgs: Array<PkgType>
): Array<PkgType> {
  return sortBy(pkgs, ['name', pkg => pkg.capacity.min]);
}
