// @flow

import { notBlank } from 'common/predicates';
import type { Pricing } from 'packages/types';

import { type LocalizedPricing, toLocalizedPrices } from 'packages/utils';

export default function summarizePricing(pricing: Pricing): ?string {
  const { vatIncluded: _, ...pricingNumbers } = pricing;
  const p = toTranslatedPricingSummaries(toLocalizedPrices(pricingNumbers));
  const summarizablePricingDetails = costBasedPricing(p);
  const [summary, ...otherPricingDetails] = summarizablePricingDetails;
  if (!summary) {
    return null;
  }
  if (otherPricingDetails.length > 0) {
    return `${summary}+`;
  }
  return summary;
}

function toTranslatedPricingSummaries(p: LocalizedPricing) {
  return {
    genericFixed: p.genericFixed
      ? I18n.t('meeting_packages.summary.pricing.generic_fixed', p)
      : null,
    perPerson: p.perPerson
      ? I18n.t('meeting_packages.summary.pricing.per_person', p)
      : null,
    rentFixed: p.rentFixed
      ? I18n.t('meeting_packages.summary.pricing.rent_fixed', p)
      : null,
    rentPerHour: p.rentPerHour
      ? I18n.t('meeting_packages.summary.pricing.rent_per_hour', p)
      : null
  };
}

function costBasedPricing(p: {|
  genericFixed: string | null,
  perPerson: string | null,
  rentFixed: string | null,
  rentPerHour: string | null
|}): Array<string> {
  return [
    p.perPerson || '',
    p.rentPerHour || '',
    p.rentFixed || '',
    p.genericFixed || ''
  ].filter(notBlank);
}
