// @flow

import { formatPrice } from 'utils/currencyUtils';
import type { Locale } from 'common/types';

import type { NumericPricing, LocalizedPricing } from './pricingTypes';

// TODO: Use the `Money` type instead of this
function maybeFormattedPrice(locale: Locale, p: ?number): ?string {
  // If the price is 0 or null, render nothing
  if (!p) {
    return null;
  }
  // If the price is a round number, leave the decimals out
  if (Number.isInteger(p)) {
    return formatPrice(p, { precision: 0, locale: locale });
  }
  // Otherwise render with locale specific precision
  return formatPrice(p, { locale: locale });
}

export default function toLocalizedPrices(p: NumericPricing): LocalizedPricing {
  const { locale } = p;
  return {
    additionalRentPerHour: maybeFormattedPrice(locale, p.additionalRentPerHour),
    genericFixed: maybeFormattedPrice(locale, p.genericFixed),
    perPerson: maybeFormattedPrice(locale, p.perPerson),
    rentFixed: maybeFormattedPrice(locale, p.rentFixed),
    rentPerHour: maybeFormattedPrice(locale, p.rentPerHour),
    salesGuarantee: maybeFormattedPrice(locale, p.salesGuarantee),
    salesGuaranteePerHour: maybeFormattedPrice(locale, p.salesGuaranteePerHour)
  };
}
